// Navbar.js
import React, { useState, useEffect } from 'react';
import { Layout, Menu, ConfigProvider, Button, Space } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HeartOutlined,
  SendOutlined,
  ProfileOutlined,
  MoonOutlined,
} from '@ant-design/icons';

const { Header, Sider, Content } = Layout;

const Navbar = ({ children, selectedKey }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setCollapsed(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menuItems = [
    { key: '1', icon: <HeartOutlined />, label: <Link to="/">home</Link> },
    { key: '2', icon: <ProfileOutlined />, label: <Link to="/schedule">schedule</Link> },
    { key: '3', icon: <MoonOutlined />, label: <Link to="/stay">stay</Link> },
    { key: '4', icon: <SendOutlined />, label: <Link to="/rsvp">rsvp</Link> },
  ];

  // Function to determine the active page based on URL
  const getActiveKey = () => {
    switch (location.pathname) {
      case '/':
        return '1';
      case '/schedule':
        return '2';
      case '/stay':
        return '3';
      case '/rsvp':
        return '4';
      default:
        return '1';
    }
  };

  const renderMenu = (
    <Menu
      mode="vertical"
      selectedKeys={[getActiveKey()]}
      items={menuItems}
      style={{
        backgroundColor: 'transparent',
        borderRadius: '8px',
        color: 'white',
        border: 'none',
      }}
      theme="dark"
    />
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#d3d3d3',
          colorBgBase: 'transparent',
          colorBgContainer: 'transparent',
          borderRadius: 2,
        },
      }}
    >
      <Layout>
        {isMobile ? (
          <>
            <Header
              className="mobile-header"
              style={{
                padding: '0 16px',
                backgroundColor: 'transparent',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: 10, // Ensure header is on top
              }}
            >
              <Space size="middle" style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  type="text"
                  icon={
                    collapsed ? (
                      <MenuUnfoldOutlined style={{ color: '#fff', fontSize: '18px' }} />
                    ) : (
                      <MenuFoldOutlined style={{ color: '#fff', fontSize: '18px' }} />
                    )
                  }
                  onClick={() => setMenuVisible(!menuVisible)}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    transition: 'none',
                  }}
                />
                <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                  <HeartOutlined
                    style={{
                      color: '#fff',
                      fontSize: '18px',
                      backgroundColor: getActiveKey() === '1' ? '#d3d3d3' : 'transparent',
                      borderRadius: '4px',
                      padding: '4px',
                    }}
                  />
                </Link>
                <Link to="/schedule" style={{ display: 'flex', alignItems: 'center' }}>
                  <ProfileOutlined
                    style={{
                      color: '#fff',
                      fontSize: '18px',
                      backgroundColor: getActiveKey() === '2' ? '#d3d3d3' : 'transparent',
                      borderRadius: '4px',
                      padding: '4px',
                    }}
                  />
                </Link>
                <Link to="/stay" style={{ display: 'flex', alignItems: 'center' }}>
                  <MoonOutlined
                    style={{
                      color: '#fff',
                      fontSize: '18px',
                      backgroundColor: getActiveKey() === '3' ? '#d3d3d3' : 'transparent',
                      borderRadius: '4px',
                      padding: '4px',
                    }}
                  />
                </Link>
                <Link to="/rsvp" style={{ display: 'flex', alignItems: 'center' }}>
                  <SendOutlined
                    style={{
                      color: '#fff',
                      fontSize: '18px',
                      backgroundColor: getActiveKey() === '4' ? '#d3d3d3' : 'transparent',
                      borderRadius: '4px',
                      padding: '4px',
                    }}
                  />
                </Link>
              </Space>
            </Header>
            {menuVisible && (
              <div
                style={{
                  padding: '8px 16px',
                  backgroundColor: 'transparent',
                  transition: 'max-height 0.3s ease',
                  overflow: 'hidden',
                  zIndex: 9, // Ensure it is above content
                  position: 'relative',
                }}
              >
                {renderMenu}
              </div>
            )}
          </>
        ) : (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[getActiveKey()]}
              items={menuItems}
              style={{
                backgroundColor: 'transparent',
              }}
            />
          </Sider>
        )}
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: '0 16px',
              backgroundColor: 'transparent',
              borderBottom: 'none',
              boxShadow: 'none',
              display: isMobile ? 'none' : 'flex',
              alignItems: 'center',
              height: '48px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '5px',
              }}
            >
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                style: { fontSize: '18px', color: '#fff', cursor: 'pointer' },
                onClick: () => setCollapsed(!collapsed),
              })}
            </div>
          </Header>
          <Content style={{
            position: isMobile ? 'absolute' : 'relative',
          }}>
            {children}
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default Navbar;
